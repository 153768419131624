.navbar {
  background-color: transparent;
  padding-top: 2.125rem !important;
  padding-bottom: 2.125rem !important;
  transition: all 0.3s;
  position: fixed !important;
  width: 100%;
  top: 0 !important;
  z-index: 1;

  a {
    cursor: pointer;
  }
  svg {
    width: 140px;
    max-height: 70px;
    -webkit-backface-visibility: hidden;
  }
  .fa-stack:hover {
    .stack {
      color: #484a46;
    }
  }
  .fa-stack {
    .hexagon {
      width: 1.625rem;
      height: 1.625rem;
      position: absolute;
      transition: all 0.2s ease;
    }
    .stack {
      text-align: center;
      width: 100%;
      font-size: 0.7rem;
      line-height: 1.625rem;
      color: #fff;
      transition: all 0.2s ease;
      position: relative;
    }
  }
}
.navbar.active {
  background-color: rgba(17, 52, 72, 0.98);
  padding: 0.325rem 0 !important;
  box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.3);
}
