.member {
  .card,
  .card-footer {
    border: none;
  }
  .card {
    background-color: inherit;
    a {
      color: #787976;
      background-color: transparent;
      font-size: 1rem;
      text-transform: none;
      font: inherit;
      line-height: 1.625rem;
      transition: 0.2s ease all;
      &:hover {
        text-decoration: underline !important;
        opacity: 0.8;
      }
      &:active {
        color: rgb(87, 169, 154);
      }
    }
    .card-body {
      padding-bottom: 0;
    }
    .card-footer {
      box-shadow: 0 0.0125rem 1.5rem 0 rgba(0, 0, 0, 0.1);
    }
  }
  .img-wrapper {
    border-radius: 50%;
    // min-height: 17.8rem;
    background-color: #fff;
    margin-bottom: 1.5rem;
    box-shadow: 0 0.9925rem 0.875rem 0 rgba(0, 0, 0, 0.05);
    img {
      margin: 0;
      border-radius: 50%;
      width: 100%;
    }
  }
  .specialization {
    margin: 0;
    color: #484a46;
    font: 700 1rem/1.375rem "Montserrat", sans-serif;
  }
  .fa-stack {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    line-height: 6rem;
    text-align: left;
    .hexagon {
      position: absolute;
      width: 2rem;
      height: 2rem;
    }
    .fa-stack-1x {
      color: #fff;
      display: flex;
      font-size: 0.9rem;
      justify-content: center;
      align-items: center;
    }
  }
}
// @media screen and (min-width: 1080px) {
//   .member {
//     .img-wrapper {
//       overflow: hidden;
//       // min-height: 17.8rem;
//       background-color: #fff;
//       margin-bottom: 1.5rem;
//       box-shadow: 0 0.9925rem 0.875rem 0 rgba(0, 0, 0, 0.05);
//       &:hover img {
//         -moz-transform: scale(1.15);
//         -webkit-transform: scale(1.15);
//         transform: scale(1.15);
//       }
//       img {
//         margin: 0;
//         transition: all 0.3s;
//       }
//     }
//   }
// }
