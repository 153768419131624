.about-us {
  background-color: white;
  padding-top: 6.875rem;
  padding-bottom: 3rem;
  .about-us-text {
    margin-top: 1.875rem;
    margin-bottom: 1.875rem;

    h1 {
      color: #484a46;
      font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
      margin-bottom: 1.375rem;
    }
    p {
      color: #787976;
      font: 400 1rem/1.5625rem "Open Sans", sans-serif;
      margin-top: 0;
      margin-bottom: 1rem;
    }
    .author {
      color: #484a46;
      font: 700 1rem/1.375rem "Montserrat", sans-serif;
    }
  }
  img {
    border-radius: 0.25rem;
    filter: grayscale(30%);
    margin: 0;
    transition: 0.2s ease all !important;
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .about-us {
    padding-top: 3.875rem;
  }
}
@media screen and (min-width: 1080px) {
  .about-us {
    .img-wrap {
      overflow: hidden;
      border-radius: 0.25rem;
      &:hover img {
        -moz-transform: scale(1.15);
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
      }
      img {
        transition: all 0.3s;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .media-text-center {
    text-align: center;
  }
}
