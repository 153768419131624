.engagement {
  padding-top: 7rem;
  padding-bottom: 5rem;
  background-color: rgba(251, 251, 251, 1);
  display: flex;
  align-items: center;
  // background-color: rgba(17, 52, 72, 1);
  h2 {
    color: #484a46;
    margin-bottom: 1.375rem;
    line-height: 2.5rem;
    font-weight: 300;
    font-style: normal;
  }
  .card {
    padding: 100px 30px 40px;
    background-color: rgba(251, 251, 251, 0.6);
    border: none;
    border-radius: 1px;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.2);
    .card-svg {
      position: absolute;
      top: 2rem;
      svg {
        color: rgba(0, 0, 0, 0.1);
        font-size: 1.7rem;
      }
    }
    .card-body {
      ul {
        list-style: none;
        padding: 0;

        li {
          svg {
            margin-right: 1rem;
            color: rgb(87, 169, 154);
          }
          span {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 26px;
            color: #484a46;
          }
        }
      }
    }
    h4 {
      margin-bottom: 4rem;
      font-size: 24px;
      line-height: 31px;
      font-weight: 100;
      font-family: Roboto, -apple-system, BlinkMacSystemFont, Segoe UI,
        Helvetica Neue, Arial, sans-serif;
    }
    a {
      border: 2px solid rgb(87, 169, 154);
      padding: 11px 13px;
      text-align: center;
      transition: 0.2s ease all;
      color: rgb(87, 169, 154);
      &:hover {
        background-color: rgb(87, 169, 154);
        color: white;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .engagement {
    padding-top: 3rem;
    .card {
      min-width: 15rem !important;
      padding: 100px 20px 40px;

      .card-body {
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 305px) {
  .engagement {
    .card {
      min-width: 13rem !important;
    }
  }
}
