.contacts {
  padding-top: 8.2rem;
  padding-bottom: 7rem;
  @media only screen and (max-width: 767px) {
    .media {
      width: 100% !important;
    }
  }
  p {
    color: #787976;
    font: 400 1rem/1.5625rem "Open Sans", sans-serif;
  }
  p.large {
    font: 400 1.125rem/1.625rem "Open Sans", sans-serif;
    color: #787976;
    margin: 0;
    margin-bottom: 0.75rem;
  }
  h1 {
    margin-bottom: 1.375rem;
    color: #484a46;
    font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
  }
  h4 {
    color: #484a46;
    font: 700 1rem/1.375rem "Montserrat", sans-serif;
    margin: 0;
  }
}
