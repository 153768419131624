.portfolio {
  .item-wrap {
    cursor: pointer;
  }
  .filter-container {
    .filter-item {
      height: 220px;
      overflow: hidden;
      // .element-item-overlay {
      //   position: absolute;
      //   z-index: 2;
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   // background-color: rgba(0, 0, 0, 0.5);
      //   background-color: rgba(17, 52, 72, 0.7);
      //   opacity: 0;
      //   text-align: center;
      //   transition: all 0.3s;
      //   &:hover {
      //     opacity: 1;
      //   }
      //   span {
      //     z-index: 3;
      //     color: #fff;
      //     font: 700 1rem/1.125rem "Montserrat", sans-serif;
      //     text-transform: uppercase;
      //     letter-spacing: 0.9px;
      //     // background-color: rgba(17, 52, 72, 0.4);
      //     // border-radius: 1px;
      //   }
      // }
      img {
        margin: auto;
        transition: all 0.3s;
        width: 75%;
        max-width: 250px;
      }
    }
  }
}

// @media screen and (max-width: 1500px) {
//   .portfolio {
//     .filter-item {
//       height: 190px !important;
//     }
//   }
// }

@media screen and (min-width: 1080px) {
  .portfolio {
    .item-wrap {
      overflow: hidden;
      &:hover img {
        -moz-transform: scale(1.15);
        -webkit-transform: scale(1.15);
        transform: scale(1.05);
        // filter: blur(1px);
      }
      img {
        width: 90%;
        transition: all 0.3s;
      }
    }
  }
}
// @media screen and (max-width: 1080px) {
//   .portfolio {
//     // .filter-item {
//     //   height: 190px !important;
//     // }
//     span {
//       font-size: 16px !important;
//     }
//     .item-wrap {
//       overflow: hidden;
//       .element-item-overlay {
//         opacity: 1 !important;
//       }
//       img {
//         width: 100%;
//         // transition: all 0.3s;
//         // -moz-transform: scale(1.15);
//         // -webkit-transform: scale(1.15);
//         // transform: scale(1.15);
//         // filter: blur(2px);
//       }
//     }
//   }
// }
