body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 400 1rem/1.5625rem "Open Sans", sans-serif;
  background-color: #113448;
}
a {
  transition: all 0.2s ease;
  cursor: pointer;
  text-decoration: none !important;
}
.row {
  margin: 0;
}
* {
  box-sizing: border-box;
}
@keyframes fadeIn {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0.2;
  animation: fadeIn ease-in 1;
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
}
