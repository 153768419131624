.footer {
  padding-top: 5rem;
  padding-bottom: 3.5rem;
  background-color: #113448;
  p {
    font-size: 0.875rem;
    line-height: 1.375rem;
    color: #dfe5ec;
    font: 400 1rem/1.5625rem "Open Sans", sans-serif;
  }
  .text-about {
    margin-bottom: 2.25rem;
    h3,
    i {
      margin-bottom: 0.75rem;
      color: #fff;
      font: 700 1.25rem/1.625rem "Montserrat", sans-serif;
    }
  }
}
