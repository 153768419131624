a {
  text-transform: uppercase;
  font: 600 0.875rem/0.875rem "Open Sans", sans-serif;
  letter-spacing: 1px;
  transition: all 0.2s ease;
}
.white {
  color: rgba(255, 255, 255, 0.9);
  &:hover {
    color: #14bf98;
  }
}
