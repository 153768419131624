.main-content {
  height: 110vh;
  width: 100%;

  .centered-content {
    width: 100%;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 0;
  }
  h1 {
    font: 700 3.5rem/4rem "Montserrat", sans-serif;
    text-transform: uppercase;
    color: white;
  }
}
.main-content:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to bottom,
      transparent,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5) 85%,
      rgba(17, 52, 72, 1) 100%
    ),
    url(../../../../images/background/app_bgr.jpg) center center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media screen and (max-width: 576px) {
  .main-content {
    h1 {
      font: 700 2.5rem/3rem "Montserrat", sans-serif;
    }
  }
}
@media screen and (max-width: 375px) {
  .main-content {
    h1 {
      font: 700 2rem/3rem "Montserrat", sans-serif;
    }
  }
}
