.back-to-top {
  position: fixed;
  // z-index: 2;
  right: 0.75rem;
  bottom: 0.75rem;
  display: none;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 1.875rem;
  background-size: 1.125rem 1.125rem;
  text-indent: -9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    transform: translateY(-0.5rem);
  }
  .arrow-back {
    height: 1rem;
    width: 1rem;
    border-style: solid;
    border-color: white;
    border-width: 0px 1px 1px 0px;
    transform: rotate(-135deg);
    transition: border-width 150ms ease-in-out;
    transition: 0.3s ease all;
    position: relative;
    margin-top: 0.5rem;
    opacity: 1;
  }
}
