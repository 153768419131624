#menu {
  padding: 100px 0 0 0;
  li {
    padding: 10px 0;
    transition-delay: 2s;
    list-style: none;
    text-align: center;
    a {
      transition: 0.2s ease all;
      color: #113448;
      font: 600 1rem/1rem "Open Sans", sans-serif;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  button {
    border: none;
    background-color: inherit;
    margin-top: 60px;
    transition: 0.2s ease all;
    color: #113448;
    font: 600 1rem/1rem "Open Sans", sans-serif;
    &:hover {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }
  }
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 29px;
  height: 15px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.2s ease all;
  position: relative;
  border-radius: 3px;
}

button {
  &:focus {
    border: none !important;
    outline: none !important;
  }
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  opacity: 0.7;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 4rem !important;
  top: 1rem !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
  height: 30px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100vh !important;
  width: 100vw !important;
  left: 0;
  top: 0;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

/* General sidebar styles */
.bm-menu {
  background-color: #f5f6fa;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
  text-align: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin: 0% !important;
  display: inline-block !important;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}
