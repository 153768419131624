.language {
  select {
    background: inherit;
    border: none;
    color: white;
    display: flex;
    font: 400 0.875rem/0.875rem "Open Sans", sans-serif;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  button.white {
    transition: 0.2s ease all;
    padding: 0 !important;
    display: flex !important;
    width: 100%;
    border: none;
    background-color: inherit !important;
    text-transform: uppercase;
    font: 400 12px "Open Sans", sans-serif;
    letter-spacing: 1px;
    cursor: pointer;
    .arrow {
      position: relative;
      height: 0.3rem;
      width: 0.3rem;
      margin-bottom: 0.2rem;
    }
    &:hover {
      .arrow {
        border-color: rgb(87, 169, 154);
        opacity: 0.7;
        transform: rotate(45deg);
      }
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  .dropdown-menu {
    text-align: center;
    transition: 0.2s ease all;
    min-width: 1rem;
    background-color: inherit;
    border-bottom-color: rgb(87, 169, 154);
    border-radius: 0%;
    padding-bottom: 0.05rem;
    transform: translate(-0.5rem, 0.5rem);
    .dropdown-item {
      margin-top: 0.3rem !important;
      opacity: 0.8;
      height: 1.5rem;
      // display: flex;
      // align-items: center;
      padding: 0;
      background-color: transparent;
      text-transform: uppercase;
      font: 400 14px "Open Sans", sans-serif;
      color: #ffffff;
      &:hover {
        opacity: 1;
        background-color: transparent;
      }
    }
  }
  .dropdown-menu.show {
    transition: 0.2s ease all;
    padding: 4px;
    background-color: rgba(17, 52, 72, 0.9);
    border-top: none;
    border-right: none;
    border-left: none;
  }
}
