.arrow {
  box-sizing: border-box;
  height: 2rem;
  width: 2rem;
  border-style: solid;
  border-color: white;
  border-width: 0px 1px 1px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
  position: absolute;
  bottom: 10%;
  right: 49.5%;
  transition: 0.3s ease all;
  opacity: 1;
}

.arrow:hover {
  transform: translateY(0.7rem) rotate(45deg);
  opacity: 0.7;
}
@media screen and (max-width: 1024px) {
  .arrow {
    right: 45.5%;
  }
}
@media screen and (max-width: 576px) {
  .arrow {
    right: 45.5%;
  }
}
@media screen and (max-width: 375px) {
  .arrow {
    right: 43.5%;
  }
}
