.portfolio {
  background-color: white;
  padding-top: 6rem;
  padding-bottom: 2.875rem;
  h2 {
    text-transform: capitalize;
    color: #484a46;
    font: 700 1.75rem/2.125rem "Montserrat", sans-serif;
    margin-bottom: 3.25rem;
  }
  .filter-nav {
    list-style: none;
    justify-content: center;
    .isotop-itme {
      letter-spacing: 0.5px;
      cursor: pointer;
      padding: 0.5rem 1rem;
      margin: 0.5rem 0.5rem;
      border-radius: 0.25rem;
      background-color: #f1f4f7;
      color: #7b7e85;
      font: 700 1rem/1.25rem "Montserrat", sans-serif;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        background-color: rgb(87, 169, 154);
        color: white;
      }
    }
    .isotop-itme.active {
      background-color: rgb(87, 169, 154);
      color: white;
    }
  }
}
